@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://fonts.googleapis.com/css2?family=Raleway:wght@700;800;900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-size: 110%;
  color: #ecf0f1;
  font-family: "Source Sans Pro", sans-serif;
  background: radial-gradient(ellipse at bottom, #000 0%, #090a0f 100%);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.main {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: "Poiret One", cursive;
  font-size: 4rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.sub-title {
  border-top: 0.1rem solid #fff;
  padding: 1rem 4rem;
  text-align: center;
}

/* CSS hack using multiple box-shadows to get them **Stars** */

.stars {
  opacity: 0.5;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1983px 1070px #fff, 1625px 734px #fff, 1505px 1218px #fff,
    1288px 1382px #fff, 1023px 982px #fff, 184px 1501px #fff, 1352px 1072px #fff,
    966px 577px #fff, 1928px 1505px #fff, 284px 1863px #fff, 871px 390px #fff,
    1591px 1017px #fff, 1919px 1315px #fff, 1552px 697px #fff,
    1775px 1722px #fff, 61px 321px #fff, 1108px 1281px #fff, 1795px 22px #fff,
    1808px 716px #fff, 797px 374px #fff, 796px 1149px #fff, 952px 1px #fff,
    1361px 1185px #fff, 1110px 1577px #fff, 1058px 1925px #fff,
    160px 1987px #fff, 1274px 1205px #fff, 39px 85px #fff, 1835px 484px #fff,
    495px 1670px #fff, 986px 691px #fff, 2px 1486px #fff, 719px 805px #fff,
    1601px 89px #fff, 591px 565px #fff, 1317px 1604px #fff, 1805px 1486px #fff,
    703px 236px #fff, 1950px 1089px #fff, 1527px 1923px #fff, 1690px 36px #fff,
    1252px 245px #fff, 343px 903px #fff, 143px 874px #fff, 939px 1705px #fff,
    1733px 372px #fff, 362px 830px #fff, 1580px 1181px #fff, 251px 535px #fff,
    1666px 1111px #fff, 1276px 1530px #fff, 958px 1989px #fff, 75px 987px #fff,
    256px 217px #fff, 635px 1194px #fff, 1004px 1546px #fff, 1910px 878px #fff,
    520px 506px #fff, 1000px 210px #fff, 1455px 1027px #fff, 417px 1714px #fff,
    1376px 674px #fff, 1744px 1390px #fff, 195px 283px #fff, 621px 1638px #fff,
    962px 997px #fff, 283px 1219px #fff, 1591px 1953px #fff, 160px 652px #fff,
    1430px 948px #fff, 1589px 1722px #fff, 614px 1305px #fff, 167px 342px #fff,
    1172px 1861px #fff, 1097px 1249px #fff, 1094px 1743px #fff,
    1988px 1361px #fff, 461px 694px #fff, 1096px 584px #fff, 1876px 1984px #fff,
    315px 245px #fff, 441px 258px #fff, 1219px 1887px #fff, 480px 245px #fff,
    285px 819px #fff, 1753px 1833px #fff, 816px 1780px #fff, 245px 1927px #fff,
    715px 1523px #fff, 475px 1595px #fff, 1162px 66px #fff, 543px 1758px #fff,
    112px 1847px #fff, 1604px 1243px #fff, 1888px 1758px #fff, 162px 1385px #fff,
    1587px 1863px #fff, 1059px 48px #fff, 1161px 1650px #fff, 1881px 289px #fff,
    475px 363px #fff, 160px 911px #fff, 487px 291px #fff, 496px 70px #fff,
    1107px 90px #fff, 731px 1771px #fff, 1517px 918px #fff, 1013px 716px #fff,
    1832px 882px #fff, 107px 393px #fff, 1503px 1860px #fff, 31px 94px #fff,
    1640px 1112px #fff, 697px 1657px #fff, 435px 81px #fff, 1526px 1467px #fff,
    602px 1001px #fff, 1409px 306px #fff, 1631px 1005px #fff, 302px 1886px #fff,
    1262px 522px #fff, 1606px 776px #fff, 1813px 1471px #fff, 1784px 1953px #fff,
    499px 1841px #fff, 805px 1191px #fff, 786px 864px #fff, 940px 1562px #fff,
    1016px 1362px #fff, 1036px 961px #fff, 1666px 173px #fff, 1214px 979px #fff,
    54px 1293px #fff, 231px 710px #fff, 434px 1321px #fff, 259px 1716px #fff,
    1463px 380px #fff, 1406px 1130px #fff, 913px 611px #fff, 1489px 1218px #fff,
    1777px 61px #fff, 967px 63px #fff, 1598px 1504px #fff, 1528px 1134px #fff,
    1641px 111px #fff, 1980px 1181px #fff, 945px 1698px #fff, 430px 1617px #fff,
    482px 85px #fff, 554px 1473px #fff, 1112px 614px #fff, 1030px 1349px #fff,
    1666px 1753px #fff, 1049px 978px #fff, 1817px 1144px #fff, 759px 1687px #fff,
    1371px 1720px #fff, 1393px 461px #fff, 26px 983px #fff, 85px 1821px #fff,
    1193px 321px #fff, 240px 64px #fff, 220px 973px #fff, 607px 312px #fff,
    1102px 679px #fff, 1314px 329px #fff, 1802px 1079px #fff, 1997px 85px #fff,
    782px 479px #fff, 1647px 52px #fff, 1945px 514px #fff, 938px 351px #fff,
    1140px 1813px #fff, 1348px 1582px #fff, 1188px 1188px #fff,
    1494px 1341px #fff, 1552px 834px #fff, 1592px 1952px #fff, 450px 98px #fff,
    581px 1213px #fff, 1736px 1193px #fff, 944px 1950px #fff, 526px 1044px #fff,
    1869px 788px #fff, 911px 1295px #fff, 1521px 1135px #fff, 1725px 1438px #fff,
    1691px 110px #fff, 1903px 1777px #fff, 643px 1826px #fff, 222px 1810px #fff,
    1613px 1035px #fff, 201px 24px #fff, 1325px 557px #fff, 1169px 1652px #fff,
    385px 290px #fff, 1733px 168px #fff, 1886px 438px #fff, 1533px 315px #fff,
    388px 960px #fff, 312px 1818px #fff, 1605px 6px #fff, 231px 1794px #fff,
    686px 1810px #fff, 1301px 577px #fff, 1583px 474px #fff, 1916px 756px #fff,
    694px 1494px #fff, 1877px 1341px #fff, 25px 6px #fff, 1717px 316px #fff,
    1906px 1307px #fff, 1555px 316px #fff, 699px 690px #fff, 1335px 368px #fff,
    1871px 668px #fff, 301px 492px #fff, 1899px 747px #fff, 680px 444px #fff,
    654px 1063px #fff, 45px 1797px #fff, 826px 281px #fff, 1041px 1859px #fff,
    1690px 7px #fff, 797px 1473px #fff, 1697px 1680px #fff, 1704px 572px #fff,
    144px 49px #fff, 1099px 264px #fff, 789px 1804px #fff, 186px 741px #fff,
    306px 1496px #fff, 473px 209px #fff, 1763px 127px #fff, 116px 288px #fff,
    450px 385px #fff, 210px 457px #fff, 1433px 1540px #fff, 1766px 1459px #fff,
    1426px 215px #fff, 1313px 296px #fff, 410px 81px #fff, 1809px 593px #fff,
    515px 216px #fff, 1903px 1912px #fff, 564px 1545px #fff, 99px 302px #fff,
    164px 1465px #fff, 1560px 801px #fff, 1839px 663px #fff, 191px 1903px #fff,
    5px 537px #fff, 1150px 38px #fff, 1310px 647px #fff, 1257px 555px #fff,
    487px 1470px #fff, 1769px 1121px #fff, 1698px 1155px #fff,
    1176px 1916px #fff, 1192px 583px #fff, 1778px 1511px #fff, 1330px 354px #fff,
    705px 737px #fff, 449px 665px #fff, 89px 1486px #fff, 751px 1974px #fff,
    1733px 1742px #fff, 1289px 1386px #fff, 1621px 1363px #fff,
    571px 1861px #fff, 1697px 1850px #fff, 504px 624px #fff, 889px 238px #fff,
    1627px 1435px #fff, 1379px 1367px #fff, 872px 550px #fff, 472px 532px #fff,
    466px 1997px #fff, 932px 1896px #fff, 1044px 83px #fff, 1262px 1593px #fff,
    1953px 1478px #fff, 1127px 1716px #fff, 325px 176px #fff, 1742px 1505px #fff,
    1088px 288px #fff, 1212px 472px #fff, 1365px 985px #fff, 1086px 803px #fff,
    256px 263px #fff, 1300px 1444px #fff, 1673px 201px #fff, 188px 1133px #fff,
    404px 483px #fff, 59px 1006px #fff, 1013px 1651px #fff, 947px 239px #fff,
    18px 178px #fff, 1430px 1851px #fff, 763px 1777px #fff, 1737px 196px #fff,
    643px 977px #fff, 1335px 1275px #fff, 1513px 1200px #fff, 497px 659px #fff,
    1929px 800px #fff, 1481px 283px #fff, 1232px 331px #fff, 1518px 1754px #fff,
    515px 869px #fff, 1127px 1290px #fff, 726px 1453px #fff, 864px 267px #fff,
    313px 1634px #fff, 1562px 606px #fff, 91px 1803px #fff, 40px 1789px #fff,
    1097px 944px #fff, 1949px 1949px #fff, 17px 1495px #fff, 1786px 93px #fff,
    577px 1866px #fff, 1252px 546px #fff, 564px 151px #fff, 1096px 1675px #fff,
    1743px 1694px #fff, 1332px 311px #fff, 1935px 1625px #fff, 1251px 900px #fff,
    840px 1126px #fff, 763px 669px #fff, 1414px 1146px #fff, 32px 1287px #fff,
    950px 739px #fff, 1730px 856px #fff, 1032px 1754px #fff, 155px 201px #fff,
    1999px 1250px #fff, 1811px 1488px #fff, 805px 281px #fff, 875px 1358px #fff,
    1757px 86px #fff, 1824px 605px #fff, 429px 453px #fff, 1955px 1159px #fff,
    19px 1874px #fff, 1549px 109px #fff, 1684px 145px #fff, 1973px 685px #fff,
    338px 839px #fff, 659px 46px #fff, 1600px 344px #fff, 838px 994px #fff,
    337px 1918px #fff, 583px 740px #fff, 687px 251px #fff, 1900px 1031px #fff,
    1296px 449px #fff, 1268px 592px #fff, 383px 1226px #fff, 868px 101px #fff,
    891px 67px #fff, 1957px 940px #fff, 74px 1445px #fff, 1432px 898px #fff,
    430px 1783px #fff, 954px 185px #fff, 359px 1455px #fff, 635px 1786px #fff,
    1186px 1259px #fff, 1445px 1505px #fff, 1177px 781px #fff, 1407px 603px #fff,
    1257px 980px #fff, 96px 562px #fff, 1999px 1560px #fff, 1153px 1554px #fff,
    1033px 672px #fff, 1051px 546px #fff, 753px 876px #fff, 1763px 145px #fff,
    1704px 1207px #fff, 34px 1261px #fff, 817px 1041px #fff, 247px 1674px #fff,
    121px 429px #fff, 515px 570px #fff, 1762px 1489px #fff, 234px 940px #fff,
    1095px 1028px #fff, 720px 1119px #fff, 1238px 334px #fff, 91px 113px #fff,
    147px 897px #fff, 1029px 1805px #fff, 1680px 1957px #fff, 475px 1503px #fff,
    1557px 917px #fff, 1458px 1762px #fff, 612px 1707px #fff, 1329px 393px #fff,
    160px 1167px #fff, 1631px 1887px #fff, 1125px 141px #fff, 257px 376px #fff,
    809px 26px #fff, 1349px 199px #fff, 1805px 88px #fff, 69px 1953px #fff,
    1206px 529px #fff, 646px 227px #fff, 1097px 1664px #fff, 42px 1261px #fff,
    1604px 360px #fff, 1939px 187px #fff, 1220px 1264px #fff, 1044px 995px #fff,
    1005px 16px #fff, 1648px 1612px #fff, 847px 1014px #fff, 339px 1584px #fff,
    1329px 1304px #fff, 961px 1970px #fff, 711px 221px #fff, 888px 566px #fff,
    53px 704px #fff, 1369px 846px #fff, 1261px 1775px #fff, 737px 1277px #fff,
    1071px 1570px #fff, 77px 1872px #fff, 1268px 1501px #fff, 598px 1640px #fff,
    1769px 297px #fff, 1074px 656px #fff, 1006px 1154px #fff, 672px 1969px #fff,
    922px 243px #fff, 622px 773px #fff, 1875px 804px #fff, 1873px 600px #fff,
    1844px 1794px #fff, 1253px 1995px #fff, 616px 1198px #fff,
    1829px 1743px #fff, 1321px 504px #fff, 1340px 566px #fff, 1520px 1825px #fff,
    1696px 1333px #fff, 537px 158px #fff, 1106px 162px #fff, 25px 514px #fff,
    1022px 422px #fff, 1955px 391px #fff, 1126px 1741px #fff, 754px 905px #fff,
    1217px 1696px #fff, 351px 270px #fff, 950px 1260px #fff, 1847px 658px #fff,
    23px 684px #fff, 1295px 572px #fff, 257px 1053px #fff, 87px 303px #fff,
    798px 1748px #fff, 1150px 142px #fff, 48px 1641px #fff, 1492px 1328px #fff,
    1385px 1766px #fff, 296px 1726px #fff, 1299px 1780px #fff, 1316px 522px #fff,
    1953px 1526px #fff, 1007px 1089px #fff, 1824px 1598px #fff,
    1720px 480px #fff, 555px 1942px #fff, 367px 432px #fff, 957px 1162px #fff,
    384px 1044px #fff, 738px 618px #fff, 1568px 1432px #fff, 211px 788px #fff,
    198px 1761px #fff, 745px 177px #fff, 1700px 159px #fff, 316px 498px #fff,
    65px 1610px #fff, 1948px 1638px #fff, 936px 1461px #fff, 1656px 622px #fff,
    575px 1437px #fff, 336px 1424px #fff, 1215px 690px #fff, 1641px 1542px #fff,
    998px 1685px #fff, 952px 1274px #fff, 1448px 1165px #fff, 904px 1783px #fff,
    1788px 1404px #fff, 1748px 1717px #fff, 793px 922px #fff, 1032px 355px #fff,
    1158px 38px #fff, 1573px 1855px #fff, 751px 1199px #fff, 1284px 227px #fff,
    391px 416px #fff, 55px 204px #fff, 962px 1918px #fff, 668px 1347px #fff,
    613px 1215px #fff, 615px 732px #fff, 1442px 1752px #fff, 1086px 1779px #fff,
    532px 320px #fff, 1228px 1321px #fff, 1796px 1906px #fff, 150px 715px #fff,
    1694px 1005px #fff, 1509px 391px #fff, 262px 303px #fff, 1743px 909px #fff,
    1966px 1203px #fff, 707px 1033px #fff, 82px 1332px #fff, 104px 794px #fff,
    246px 759px #fff, 507px 1380px #fff, 1227px 616px #fff, 917px 1354px #fff,
    1941px 759px #fff, 451px 852px #fff, 17px 131px #fff, 780px 476px #fff,
    816px 211px #fff, 1039px 1245px #fff, 1521px 1036px #fff, 1509px 1542px #fff,
    1368px 960px #fff, 1104px 1437px #fff, 1171px 976px #fff, 681px 1305px #fff,
    1741px 579px #fff, 953px 704px #fff, 1810px 328px #fff, 1960px 1396px #fff,
    1098px 295px #fff, 1409px 919px #fff, 1830px 1176px #fff, 492px 1063px #fff,
    1277px 515px #fff, 602px 1546px #fff, 1832px 236px #fff, 1513px 274px #fff,
    1983px 794px #fff, 2000px 1505px #fff, 920px 382px #fff, 1374px 1235px #fff,
    692px 310px #fff, 1028px 1782px #fff, 1007px 887px #fff, 1492px 858px #fff,
    877px 1817px #fff, 1024px 1942px #fff, 658px 82px #fff, 526px 742px #fff,
    755px 880px #fff, 301px 1186px #fff, 318px 31px #fff, 1517px 1244px #fff,
    1996px 1126px #fff, 464px 1861px #fff, 334px 674px #fff, 768px 1103px #fff,
    628px 890px #fff, 1054px 1534px #fff, 59px 1576px #fff, 189px 693px #fff,
    16px 1097px #fff, 563px 1186px #fff, 428px 523px #fff, 1047px 1231px #fff,
    1940px 1262px #fff, 643px 971px #fff, 611px 1794px #fff, 1676px 21px #fff,
    1882px 1523px #fff, 258px 1584px #fff, 768px 295px #fff, 1253px 1431px #fff,
    1855px 362px #fff, 576px 987px #fff, 1904px 470px #fff, 895px 1390px #fff,
    771px 966px #fff, 1034px 1815px #fff, 203px 1292px #fff, 566px 893px #fff,
    63px 913px #fff, 1340px 1416px #fff, 528px 967px #fff, 1965px 444px #fff,
    1961px 132px #fff, 1049px 577px #fff, 459px 1031px #fff, 261px 1780px #fff,
    1092px 1480px #fff, 286px 759px #fff, 1014px 1604px #fff, 144px 1461px #fff,
    1180px 1694px #fff, 1348px 613px #fff, 518px 1479px #fff, 478px 649px #fff,
    1303px 629px #fff, 1713px 258px #fff, 1912px 501px #fff, 300px 227px #fff,
    989px 1068px #fff, 197px 158px #fff, 244px 420px #fff, 889px 486px #fff,
    1517px 1812px #fff, 965px 308px #fff, 409px 1293px #fff, 47px 1400px #fff,
    1473px 1150px #fff, 311px 1711px #fff, 1938px 1000px #fff, 717px 1331px #fff,
    282px 881px #fff, 1305px 382px #fff, 1639px 52px #fff, 1466px 1228px #fff,
    574px 26px #fff, 1310px 899px #fff, 15px 1795px #fff, 1138px 941px #fff,
    1235px 1416px #fff, 27px 375px #fff, 1333px 1470px #fff, 1462px 1837px #fff,
    1830px 1217px #fff, 1521px 1848px #fff, 730px 515px #fff, 514px 1869px #fff,
    468px 1906px #fff, 1931px 259px #fff, 1612px 151px #fff, 1495px 735px #fff,
    710px 1096px #fff, 1976px 546px #fff, 50px 741px #fff, 1313px 381px #fff,
    54px 102px #fff, 1873px 1009px #fff, 1696px 688px #fff, 626px 922px #fff,
    244px 1438px #fff, 1746px 1787px #fff, 1824px 1437px #fff,
    1730px 1219px #fff, 785px 198px #fff, 1174px 931px #fff, 909px 517px #fff,
    1283px 662px #fff, 1074px 194px #fff, 847px 1945px #fff, 113px 427px #fff,
    728px 352px #fff, 1205px 1316px #fff, 786px 668px #fff, 64px 427px #fff,
    548px 1370px #fff, 1102px 616px #fff, 676px 1192px #fff, 1652px 1264px #fff,
    649px 69px #fff, 1593px 645px #fff, 1651px 816px #fff, 1914px 977px #fff,
    80px 1006px #fff, 1252px 742px #fff, 1291px 981px #fff, 1325px 273px #fff,
    831px 1581px #fff, 344px 1503px #fff, 1885px 1849px #fff, 745px 1030px #fff,
    764px 1396px #fff, 1241px 829px #fff, 1281px 160px #fff, 1210px 1860px #fff,
    1998px 411px #fff, 1752px 1816px #fff, 21px 1656px #fff, 1863px 1988px #fff,
    1860px 331px #fff, 79px 512px #fff;
  animation: animStar 50s linear infinite;
}

.stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1983px 1070px #fff, 1625px 734px #fff, 1505px 1218px #fff,
    1288px 1382px #fff, 1023px 982px #fff, 184px 1501px #fff, 1352px 1072px #fff,
    966px 577px #fff, 1928px 1505px #fff, 284px 1863px #fff, 871px 390px #fff,
    1591px 1017px #fff, 1919px 1315px #fff, 1552px 697px #fff,
    1775px 1722px #fff, 61px 321px #fff, 1108px 1281px #fff, 1795px 22px #fff,
    1808px 716px #fff, 797px 374px #fff, 796px 1149px #fff, 952px 1px #fff,
    1361px 1185px #fff, 1110px 1577px #fff, 1058px 1925px #fff,
    160px 1987px #fff, 1274px 1205px #fff, 39px 85px #fff, 1835px 484px #fff,
    495px 1670px #fff, 986px 691px #fff, 2px 1486px #fff, 719px 805px #fff,
    1601px 89px #fff, 591px 565px #fff, 1317px 1604px #fff, 1805px 1486px #fff,
    703px 236px #fff, 1950px 1089px #fff, 1527px 1923px #fff, 1690px 36px #fff,
    1252px 245px #fff, 343px 903px #fff, 143px 874px #fff, 939px 1705px #fff,
    1733px 372px #fff, 362px 830px #fff, 1580px 1181px #fff, 251px 535px #fff,
    1666px 1111px #fff, 1276px 1530px #fff, 958px 1989px #fff, 75px 987px #fff,
    256px 217px #fff, 635px 1194px #fff, 1004px 1546px #fff, 1910px 878px #fff,
    520px 506px #fff, 1000px 210px #fff, 1455px 1027px #fff, 417px 1714px #fff,
    1376px 674px #fff, 1744px 1390px #fff, 195px 283px #fff, 621px 1638px #fff,
    962px 997px #fff, 283px 1219px #fff, 1591px 1953px #fff, 160px 652px #fff,
    1430px 948px #fff, 1589px 1722px #fff, 614px 1305px #fff, 167px 342px #fff,
    1172px 1861px #fff, 1097px 1249px #fff, 1094px 1743px #fff,
    1988px 1361px #fff, 461px 694px #fff, 1096px 584px #fff, 1876px 1984px #fff,
    315px 245px #fff, 441px 258px #fff, 1219px 1887px #fff, 480px 245px #fff,
    285px 819px #fff, 1753px 1833px #fff, 816px 1780px #fff, 245px 1927px #fff,
    715px 1523px #fff, 475px 1595px #fff, 1162px 66px #fff, 543px 1758px #fff,
    112px 1847px #fff, 1604px 1243px #fff, 1888px 1758px #fff, 162px 1385px #fff,
    1587px 1863px #fff, 1059px 48px #fff, 1161px 1650px #fff, 1881px 289px #fff,
    475px 363px #fff, 160px 911px #fff, 487px 291px #fff, 496px 70px #fff,
    1107px 90px #fff, 731px 1771px #fff, 1517px 918px #fff, 1013px 716px #fff,
    1832px 882px #fff, 107px 393px #fff, 1503px 1860px #fff, 31px 94px #fff,
    1640px 1112px #fff, 697px 1657px #fff, 435px 81px #fff, 1526px 1467px #fff,
    602px 1001px #fff, 1409px 306px #fff, 1631px 1005px #fff, 302px 1886px #fff,
    1262px 522px #fff, 1606px 776px #fff, 1813px 1471px #fff, 1784px 1953px #fff,
    499px 1841px #fff, 805px 1191px #fff, 786px 864px #fff, 940px 1562px #fff,
    1016px 1362px #fff, 1036px 961px #fff, 1666px 173px #fff, 1214px 979px #fff,
    54px 1293px #fff, 231px 710px #fff, 434px 1321px #fff, 259px 1716px #fff,
    1463px 380px #fff, 1406px 1130px #fff, 913px 611px #fff, 1489px 1218px #fff,
    1777px 61px #fff, 967px 63px #fff, 1598px 1504px #fff, 1528px 1134px #fff,
    1641px 111px #fff, 1980px 1181px #fff, 945px 1698px #fff, 430px 1617px #fff,
    482px 85px #fff, 554px 1473px #fff, 1112px 614px #fff, 1030px 1349px #fff,
    1666px 1753px #fff, 1049px 978px #fff, 1817px 1144px #fff, 759px 1687px #fff,
    1371px 1720px #fff, 1393px 461px #fff, 26px 983px #fff, 85px 1821px #fff,
    1193px 321px #fff, 240px 64px #fff, 220px 973px #fff, 607px 312px #fff,
    1102px 679px #fff, 1314px 329px #fff, 1802px 1079px #fff, 1997px 85px #fff,
    782px 479px #fff, 1647px 52px #fff, 1945px 514px #fff, 938px 351px #fff,
    1140px 1813px #fff, 1348px 1582px #fff, 1188px 1188px #fff,
    1494px 1341px #fff, 1552px 834px #fff, 1592px 1952px #fff, 450px 98px #fff,
    581px 1213px #fff, 1736px 1193px #fff, 944px 1950px #fff, 526px 1044px #fff,
    1869px 788px #fff, 911px 1295px #fff, 1521px 1135px #fff, 1725px 1438px #fff,
    1691px 110px #fff, 1903px 1777px #fff, 643px 1826px #fff, 222px 1810px #fff,
    1613px 1035px #fff, 201px 24px #fff, 1325px 557px #fff, 1169px 1652px #fff,
    385px 290px #fff, 1733px 168px #fff, 1886px 438px #fff, 1533px 315px #fff,
    388px 960px #fff, 312px 1818px #fff, 1605px 6px #fff, 231px 1794px #fff,
    686px 1810px #fff, 1301px 577px #fff, 1583px 474px #fff, 1916px 756px #fff,
    694px 1494px #fff, 1877px 1341px #fff, 25px 6px #fff, 1717px 316px #fff,
    1906px 1307px #fff, 1555px 316px #fff, 699px 690px #fff, 1335px 368px #fff,
    1871px 668px #fff, 301px 492px #fff, 1899px 747px #fff, 680px 444px #fff,
    654px 1063px #fff, 45px 1797px #fff, 826px 281px #fff, 1041px 1859px #fff,
    1690px 7px #fff, 797px 1473px #fff, 1697px 1680px #fff, 1704px 572px #fff,
    144px 49px #fff, 1099px 264px #fff, 789px 1804px #fff, 186px 741px #fff,
    306px 1496px #fff, 473px 209px #fff, 1763px 127px #fff, 116px 288px #fff,
    450px 385px #fff, 210px 457px #fff, 1433px 1540px #fff, 1766px 1459px #fff,
    1426px 215px #fff, 1313px 296px #fff, 410px 81px #fff, 1809px 593px #fff,
    515px 216px #fff, 1903px 1912px #fff, 564px 1545px #fff, 99px 302px #fff,
    164px 1465px #fff, 1560px 801px #fff, 1839px 663px #fff, 191px 1903px #fff,
    5px 537px #fff, 1150px 38px #fff, 1310px 647px #fff, 1257px 555px #fff,
    487px 1470px #fff, 1769px 1121px #fff, 1698px 1155px #fff,
    1176px 1916px #fff, 1192px 583px #fff, 1778px 1511px #fff, 1330px 354px #fff,
    705px 737px #fff, 449px 665px #fff, 89px 1486px #fff, 751px 1974px #fff,
    1733px 1742px #fff, 1289px 1386px #fff, 1621px 1363px #fff,
    571px 1861px #fff, 1697px 1850px #fff, 504px 624px #fff, 889px 238px #fff,
    1627px 1435px #fff, 1379px 1367px #fff, 872px 550px #fff, 472px 532px #fff,
    466px 1997px #fff, 932px 1896px #fff, 1044px 83px #fff, 1262px 1593px #fff,
    1953px 1478px #fff, 1127px 1716px #fff, 325px 176px #fff, 1742px 1505px #fff,
    1088px 288px #fff, 1212px 472px #fff, 1365px 985px #fff, 1086px 803px #fff,
    256px 263px #fff, 1300px 1444px #fff, 1673px 201px #fff, 188px 1133px #fff,
    404px 483px #fff, 59px 1006px #fff, 1013px 1651px #fff, 947px 239px #fff,
    18px 178px #fff, 1430px 1851px #fff, 763px 1777px #fff, 1737px 196px #fff,
    643px 977px #fff, 1335px 1275px #fff, 1513px 1200px #fff, 497px 659px #fff,
    1929px 800px #fff, 1481px 283px #fff, 1232px 331px #fff, 1518px 1754px #fff,
    515px 869px #fff, 1127px 1290px #fff, 726px 1453px #fff, 864px 267px #fff,
    313px 1634px #fff, 1562px 606px #fff, 91px 1803px #fff, 40px 1789px #fff,
    1097px 944px #fff, 1949px 1949px #fff, 17px 1495px #fff, 1786px 93px #fff,
    577px 1866px #fff, 1252px 546px #fff, 564px 151px #fff, 1096px 1675px #fff,
    1743px 1694px #fff, 1332px 311px #fff, 1935px 1625px #fff, 1251px 900px #fff,
    840px 1126px #fff, 763px 669px #fff, 1414px 1146px #fff, 32px 1287px #fff,
    950px 739px #fff, 1730px 856px #fff, 1032px 1754px #fff, 155px 201px #fff,
    1999px 1250px #fff, 1811px 1488px #fff, 805px 281px #fff, 875px 1358px #fff,
    1757px 86px #fff, 1824px 605px #fff, 429px 453px #fff, 1955px 1159px #fff,
    19px 1874px #fff, 1549px 109px #fff, 1684px 145px #fff, 1973px 685px #fff,
    338px 839px #fff, 659px 46px #fff, 1600px 344px #fff, 838px 994px #fff,
    337px 1918px #fff, 583px 740px #fff, 687px 251px #fff, 1900px 1031px #fff,
    1296px 449px #fff, 1268px 592px #fff, 383px 1226px #fff, 868px 101px #fff,
    891px 67px #fff, 1957px 940px #fff, 74px 1445px #fff, 1432px 898px #fff,
    430px 1783px #fff, 954px 185px #fff, 359px 1455px #fff, 635px 1786px #fff,
    1186px 1259px #fff, 1445px 1505px #fff, 1177px 781px #fff, 1407px 603px #fff,
    1257px 980px #fff, 96px 562px #fff, 1999px 1560px #fff, 1153px 1554px #fff,
    1033px 672px #fff, 1051px 546px #fff, 753px 876px #fff, 1763px 145px #fff,
    1704px 1207px #fff, 34px 1261px #fff, 817px 1041px #fff, 247px 1674px #fff,
    121px 429px #fff, 515px 570px #fff, 1762px 1489px #fff, 234px 940px #fff,
    1095px 1028px #fff, 720px 1119px #fff, 1238px 334px #fff, 91px 113px #fff,
    147px 897px #fff, 1029px 1805px #fff, 1680px 1957px #fff, 475px 1503px #fff,
    1557px 917px #fff, 1458px 1762px #fff, 612px 1707px #fff, 1329px 393px #fff,
    160px 1167px #fff, 1631px 1887px #fff, 1125px 141px #fff, 257px 376px #fff,
    809px 26px #fff, 1349px 199px #fff, 1805px 88px #fff, 69px 1953px #fff,
    1206px 529px #fff, 646px 227px #fff, 1097px 1664px #fff, 42px 1261px #fff,
    1604px 360px #fff, 1939px 187px #fff, 1220px 1264px #fff, 1044px 995px #fff,
    1005px 16px #fff, 1648px 1612px #fff, 847px 1014px #fff, 339px 1584px #fff,
    1329px 1304px #fff, 961px 1970px #fff, 711px 221px #fff, 888px 566px #fff,
    53px 704px #fff, 1369px 846px #fff, 1261px 1775px #fff, 737px 1277px #fff,
    1071px 1570px #fff, 77px 1872px #fff, 1268px 1501px #fff, 598px 1640px #fff,
    1769px 297px #fff, 1074px 656px #fff, 1006px 1154px #fff, 672px 1969px #fff,
    922px 243px #fff, 622px 773px #fff, 1875px 804px #fff, 1873px 600px #fff,
    1844px 1794px #fff, 1253px 1995px #fff, 616px 1198px #fff,
    1829px 1743px #fff, 1321px 504px #fff, 1340px 566px #fff, 1520px 1825px #fff,
    1696px 1333px #fff, 537px 158px #fff, 1106px 162px #fff, 25px 514px #fff,
    1022px 422px #fff, 1955px 391px #fff, 1126px 1741px #fff, 754px 905px #fff,
    1217px 1696px #fff, 351px 270px #fff, 950px 1260px #fff, 1847px 658px #fff,
    23px 684px #fff, 1295px 572px #fff, 257px 1053px #fff, 87px 303px #fff,
    798px 1748px #fff, 1150px 142px #fff, 48px 1641px #fff, 1492px 1328px #fff,
    1385px 1766px #fff, 296px 1726px #fff, 1299px 1780px #fff, 1316px 522px #fff,
    1953px 1526px #fff, 1007px 1089px #fff, 1824px 1598px #fff,
    1720px 480px #fff, 555px 1942px #fff, 367px 432px #fff, 957px 1162px #fff,
    384px 1044px #fff, 738px 618px #fff, 1568px 1432px #fff, 211px 788px #fff,
    198px 1761px #fff, 745px 177px #fff, 1700px 159px #fff, 316px 498px #fff,
    65px 1610px #fff, 1948px 1638px #fff, 936px 1461px #fff, 1656px 622px #fff,
    575px 1437px #fff, 336px 1424px #fff, 1215px 690px #fff, 1641px 1542px #fff,
    998px 1685px #fff, 952px 1274px #fff, 1448px 1165px #fff, 904px 1783px #fff,
    1788px 1404px #fff, 1748px 1717px #fff, 793px 922px #fff, 1032px 355px #fff,
    1158px 38px #fff, 1573px 1855px #fff, 751px 1199px #fff, 1284px 227px #fff,
    391px 416px #fff, 55px 204px #fff, 962px 1918px #fff, 668px 1347px #fff,
    613px 1215px #fff, 615px 732px #fff, 1442px 1752px #fff, 1086px 1779px #fff,
    532px 320px #fff, 1228px 1321px #fff, 1796px 1906px #fff, 150px 715px #fff,
    1694px 1005px #fff, 1509px 391px #fff, 262px 303px #fff, 1743px 909px #fff,
    1966px 1203px #fff, 707px 1033px #fff, 82px 1332px #fff, 104px 794px #fff,
    246px 759px #fff, 507px 1380px #fff, 1227px 616px #fff, 917px 1354px #fff,
    1941px 759px #fff, 451px 852px #fff, 17px 131px #fff, 780px 476px #fff,
    816px 211px #fff, 1039px 1245px #fff, 1521px 1036px #fff, 1509px 1542px #fff,
    1368px 960px #fff, 1104px 1437px #fff, 1171px 976px #fff, 681px 1305px #fff,
    1741px 579px #fff, 953px 704px #fff, 1810px 328px #fff, 1960px 1396px #fff,
    1098px 295px #fff, 1409px 919px #fff, 1830px 1176px #fff, 492px 1063px #fff,
    1277px 515px #fff, 602px 1546px #fff, 1832px 236px #fff, 1513px 274px #fff,
    1983px 794px #fff, 2000px 1505px #fff, 920px 382px #fff, 1374px 1235px #fff,
    692px 310px #fff, 1028px 1782px #fff, 1007px 887px #fff, 1492px 858px #fff,
    877px 1817px #fff, 1024px 1942px #fff, 658px 82px #fff, 526px 742px #fff,
    755px 880px #fff, 301px 1186px #fff, 318px 31px #fff, 1517px 1244px #fff,
    1996px 1126px #fff, 464px 1861px #fff, 334px 674px #fff, 768px 1103px #fff,
    628px 890px #fff, 1054px 1534px #fff, 59px 1576px #fff, 189px 693px #fff,
    16px 1097px #fff, 563px 1186px #fff, 428px 523px #fff, 1047px 1231px #fff,
    1940px 1262px #fff, 643px 971px #fff, 611px 1794px #fff, 1676px 21px #fff,
    1882px 1523px #fff, 258px 1584px #fff, 768px 295px #fff, 1253px 1431px #fff,
    1855px 362px #fff, 576px 987px #fff, 1904px 470px #fff, 895px 1390px #fff,
    771px 966px #fff, 1034px 1815px #fff, 203px 1292px #fff, 566px 893px #fff,
    63px 913px #fff, 1340px 1416px #fff, 528px 967px #fff, 1965px 444px #fff,
    1961px 132px #fff, 1049px 577px #fff, 459px 1031px #fff, 261px 1780px #fff,
    1092px 1480px #fff, 286px 759px #fff, 1014px 1604px #fff, 144px 1461px #fff,
    1180px 1694px #fff, 1348px 613px #fff, 518px 1479px #fff, 478px 649px #fff,
    1303px 629px #fff, 1713px 258px #fff, 1912px 501px #fff, 300px 227px #fff,
    989px 1068px #fff, 197px 158px #fff, 244px 420px #fff, 889px 486px #fff,
    1517px 1812px #fff, 965px 308px #fff, 409px 1293px #fff, 47px 1400px #fff,
    1473px 1150px #fff, 311px 1711px #fff, 1938px 1000px #fff, 717px 1331px #fff,
    282px 881px #fff, 1305px 382px #fff, 1639px 52px #fff, 1466px 1228px #fff,
    574px 26px #fff, 1310px 899px #fff, 15px 1795px #fff, 1138px 941px #fff,
    1235px 1416px #fff, 27px 375px #fff, 1333px 1470px #fff, 1462px 1837px #fff,
    1830px 1217px #fff, 1521px 1848px #fff, 730px 515px #fff, 514px 1869px #fff,
    468px 1906px #fff, 1931px 259px #fff, 1612px 151px #fff, 1495px 735px #fff,
    710px 1096px #fff, 1976px 546px #fff, 50px 741px #fff, 1313px 381px #fff,
    54px 102px #fff, 1873px 1009px #fff, 1696px 688px #fff, 626px 922px #fff,
    244px 1438px #fff, 1746px 1787px #fff, 1824px 1437px #fff,
    1730px 1219px #fff, 785px 198px #fff, 1174px 931px #fff, 909px 517px #fff,
    1283px 662px #fff, 1074px 194px #fff, 847px 1945px #fff, 113px 427px #fff,
    728px 352px #fff, 1205px 1316px #fff, 786px 668px #fff, 64px 427px #fff,
    548px 1370px #fff, 1102px 616px #fff, 676px 1192px #fff, 1652px 1264px #fff,
    649px 69px #fff, 1593px 645px #fff, 1651px 816px #fff, 1914px 977px #fff,
    80px 1006px #fff, 1252px 742px #fff, 1291px 981px #fff, 1325px 273px #fff,
    831px 1581px #fff, 344px 1503px #fff, 1885px 1849px #fff, 745px 1030px #fff,
    764px 1396px #fff, 1241px 829px #fff, 1281px 160px #fff, 1210px 1860px #fff,
    1998px 411px #fff, 1752px 1816px #fff, 21px 1656px #fff, 1863px 1988px #fff,
    1860px 331px #fff, 79px 512px #fff;
}

.stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1124px 1500px #fff, 916px 1039px #fff, 1281px 2000px #fff,
    1702px 518px #fff, 246px 953px #fff, 1889px 287px #fff, 1438px 1139px #fff,
    1824px 1739px #fff, 1090px 1205px #fff, 1514px 392px #fff, 87px 1797px #fff,
    342px 25px #fff, 134px 1624px #fff, 1246px 426px #fff, 453px 559px #fff,
    609px 308px #fff, 506px 972px #fff, 684px 733px #fff, 85px 1641px #fff,
    1576px 320px #fff, 145px 809px #fff, 1822px 1000px #fff, 21px 1512px #fff,
    173px 1775px #fff, 1283px 1564px #fff, 224px 1353px #fff, 451px 785px #fff,
    703px 1983px #fff, 508px 1345px #fff, 957px 1209px #fff, 698px 162px #fff,
    159px 1835px #fff, 712px 227px #fff, 1234px 295px #fff, 310px 958px #fff,
    889px 31px #fff, 1321px 1156px #fff, 501px 274px #fff, 914px 96px #fff,
    1637px 1954px #fff, 774px 1326px #fff, 304px 550px #fff, 1249px 425px #fff,
    649px 1851px #fff, 723px 1114px #fff, 1115px 1524px #fff, 1937px 1932px #fff,
    44px 1353px #fff, 850px 1872px #fff, 1175px 1628px #fff, 318px 248px #fff,
    291px 521px #fff, 1246px 1707px #fff, 1640px 1608px #fff, 1961px 343px #fff,
    964px 1144px #fff, 1729px 361px #fff, 1493px 799px #fff, 1503px 1030px #fff,
    426px 1162px #fff, 991px 1700px #fff, 697px 630px #fff, 897px 1692px #fff,
    457px 1816px #fff, 667px 729px #fff, 1753px 1618px #fff, 1132px 1095px #fff,
    954px 1846px #fff, 1938px 1179px #fff, 135px 434px #fff, 1134px 1216px #fff,
    491px 543px #fff, 525px 1114px #fff, 1769px 1830px #fff, 1641px 1422px #fff,
    873px 548px #fff, 1519px 1249px #fff, 934px 1084px #fff, 753px 1528px #fff,
    1139px 478px #fff, 1533px 356px #fff, 264px 1956px #fff, 899px 487px #fff,
    1932px 1084px #fff, 1846px 64px #fff, 46px 1255px #fff, 102px 1775px #fff,
    678px 1579px #fff, 1406px 368px #fff, 1808px 32px #fff, 741px 775px #fff,
    225px 1338px #fff, 124px 731px #fff, 411px 135px #fff, 1640px 1276px #fff,
    54px 1998px #fff, 1126px 1912px #fff, 1896px 1754px #fff, 819px 827px #fff,
    1063px 125px #fff, 955px 1357px #fff, 945px 1808px #fff, 777px 1591px #fff,
    508px 1351px #fff, 966px 983px #fff, 168px 9px #fff, 1700px 533px #fff,
    785px 1700px #fff, 558px 749px #fff, 56px 1463px #fff, 1482px 371px #fff,
    1201px 1930px #fff, 207px 240px #fff, 538px 146px #fff, 893px 1156px #fff,
    662px 973px #fff, 1126px 641px #fff, 354px 1792px #fff, 1483px 1367px #fff,
    1032px 1516px #fff, 322px 1338px #fff, 1804px 1170px #fff, 1899px 561px #fff,
    698px 1947px #fff, 1377px 1501px #fff, 1966px 1196px #fff, 108px 1889px #fff,
    790px 1309px #fff, 1206px 42px #fff, 1903px 373px #fff, 465px 113px #fff,
    1776px 1878px #fff, 1363px 639px #fff, 1142px 1969px #fff, 1427px 840px #fff,
    212px 1412px #fff, 1333px 1905px #fff, 817px 261px #fff, 1590px 1046px #fff,
    1139px 89px #fff, 1852px 311px #fff, 1618px 1155px #fff, 387px 1975px #fff,
    290px 1660px #fff, 1094px 957px #fff, 1145px 1157px #fff, 825px 1179px #fff,
    1456px 1133px #fff, 1214px 922px #fff, 290px 1880px #fff, 852px 1027px #fff,
    275px 557px #fff, 1115px 724px #fff, 1713px 1698px #fff, 1819px 1196px #fff,
    1778px 179px #fff, 401px 610px #fff, 1288px 1286px #fff, 1603px 493px #fff,
    497px 306px #fff, 457px 1005px #fff, 675px 1814px #fff, 850px 1719px #fff,
    467px 932px #fff, 1312px 1366px #fff, 812px 1643px #fff, 356px 1170px #fff,
    1915px 787px #fff, 1923px 1453px #fff, 542px 860px #fff, 1496px 832px #fff,
    1689px 90px #fff, 1404px 73px #fff, 991px 357px #fff, 1534px 1469px #fff,
    1036px 822px #fff, 595px 819px #fff, 432px 1908px #fff, 221px 1519px #fff,
    1744px 1507px #fff, 505px 564px #fff, 1220px 1959px #fff, 544px 1880px #fff,
    744px 516px #fff, 547px 307px #fff, 1862px 247px #fff, 1560px 410px #fff,
    736px 282px #fff, 986px 1452px #fff, 808px 1942px #fff, 194px 1307px #fff,
    639px 842px #fff, 717px 272px #fff, 580px 103px #fff, 566px 1916px #fff,
    1847px 91px #fff, 1583px 629px #fff, 1270px 1517px #fff, 1452px 1905px #fff,
    1161px 1965px #fff;
  animation: animStar 100s linear infinite;
}

.stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1124px 1500px #fff, 916px 1039px #fff, 1281px 2000px #fff,
    1702px 518px #fff, 246px 953px #fff, 1889px 287px #fff, 1438px 1139px #fff,
    1824px 1739px #fff, 1090px 1205px #fff, 1514px 392px #fff, 87px 1797px #fff,
    342px 25px #fff, 134px 1624px #fff, 1246px 426px #fff, 453px 559px #fff,
    609px 308px #fff, 506px 972px #fff, 684px 733px #fff, 85px 1641px #fff,
    1576px 320px #fff, 145px 809px #fff, 1822px 1000px #fff, 21px 1512px #fff,
    173px 1775px #fff, 1283px 1564px #fff, 224px 1353px #fff, 451px 785px #fff,
    703px 1983px #fff, 508px 1345px #fff, 957px 1209px #fff, 698px 162px #fff,
    159px 1835px #fff, 712px 227px #fff, 1234px 295px #fff, 310px 958px #fff,
    889px 31px #fff, 1321px 1156px #fff, 501px 274px #fff, 914px 96px #fff,
    1637px 1954px #fff, 774px 1326px #fff, 304px 550px #fff, 1249px 425px #fff,
    649px 1851px #fff, 723px 1114px #fff, 1115px 1524px #fff, 1937px 1932px #fff,
    44px 1353px #fff, 850px 1872px #fff, 1175px 1628px #fff, 318px 248px #fff,
    291px 521px #fff, 1246px 1707px #fff, 1640px 1608px #fff, 1961px 343px #fff,
    964px 1144px #fff, 1729px 361px #fff, 1493px 799px #fff, 1503px 1030px #fff,
    426px 1162px #fff, 991px 1700px #fff, 697px 630px #fff, 897px 1692px #fff,
    457px 1816px #fff, 667px 729px #fff, 1753px 1618px #fff, 1132px 1095px #fff,
    954px 1846px #fff, 1938px 1179px #fff, 135px 434px #fff, 1134px 1216px #fff,
    491px 543px #fff, 525px 1114px #fff, 1769px 1830px #fff, 1641px 1422px #fff,
    873px 548px #fff, 1519px 1249px #fff, 934px 1084px #fff, 753px 1528px #fff,
    1139px 478px #fff, 1533px 356px #fff, 264px 1956px #fff, 899px 487px #fff,
    1932px 1084px #fff, 1846px 64px #fff, 46px 1255px #fff, 102px 1775px #fff,
    678px 1579px #fff, 1406px 368px #fff, 1808px 32px #fff, 741px 775px #fff,
    225px 1338px #fff, 124px 731px #fff, 411px 135px #fff, 1640px 1276px #fff,
    54px 1998px #fff, 1126px 1912px #fff, 1896px 1754px #fff, 819px 827px #fff,
    1063px 125px #fff, 955px 1357px #fff, 945px 1808px #fff, 777px 1591px #fff,
    508px 1351px #fff, 966px 983px #fff, 168px 9px #fff, 1700px 533px #fff,
    785px 1700px #fff, 558px 749px #fff, 56px 1463px #fff, 1482px 371px #fff,
    1201px 1930px #fff, 207px 240px #fff, 538px 146px #fff, 893px 1156px #fff,
    662px 973px #fff, 1126px 641px #fff, 354px 1792px #fff, 1483px 1367px #fff,
    1032px 1516px #fff, 322px 1338px #fff, 1804px 1170px #fff, 1899px 561px #fff,
    698px 1947px #fff, 1377px 1501px #fff, 1966px 1196px #fff, 108px 1889px #fff,
    790px 1309px #fff, 1206px 42px #fff, 1903px 373px #fff, 465px 113px #fff,
    1776px 1878px #fff, 1363px 639px #fff, 1142px 1969px #fff, 1427px 840px #fff,
    212px 1412px #fff, 1333px 1905px #fff, 817px 261px #fff, 1590px 1046px #fff,
    1139px 89px #fff, 1852px 311px #fff, 1618px 1155px #fff, 387px 1975px #fff,
    290px 1660px #fff, 1094px 957px #fff, 1145px 1157px #fff, 825px 1179px #fff,
    1456px 1133px #fff, 1214px 922px #fff, 290px 1880px #fff, 852px 1027px #fff,
    275px 557px #fff, 1115px 724px #fff, 1713px 1698px #fff, 1819px 1196px #fff,
    1778px 179px #fff, 401px 610px #fff, 1288px 1286px #fff, 1603px 493px #fff,
    497px 306px #fff, 457px 1005px #fff, 675px 1814px #fff, 850px 1719px #fff,
    467px 932px #fff, 1312px 1366px #fff, 812px 1643px #fff, 356px 1170px #fff,
    1915px 787px #fff, 1923px 1453px #fff, 542px 860px #fff, 1496px 832px #fff,
    1689px 90px #fff, 1404px 73px #fff, 991px 357px #fff, 1534px 1469px #fff,
    1036px 822px #fff, 595px 819px #fff, 432px 1908px #fff, 221px 1519px #fff,
    1744px 1507px #fff, 505px 564px #fff, 1220px 1959px #fff, 544px 1880px #fff,
    744px 516px #fff, 547px 307px #fff, 1862px 247px #fff, 1560px 410px #fff,
    736px 282px #fff, 986px 1452px #fff, 808px 1942px #fff, 194px 1307px #fff,
    639px 842px #fff, 717px 272px #fff, 580px 103px #fff, 566px 1916px #fff,
    1847px 91px #fff, 1583px 629px #fff, 1270px 1517px #fff, 1452px 1905px #fff,
    1161px 1965px #fff;
}

.stars3 {
  opacity: 0.7;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1106px 1979px #fff, 1572px 505px #fff, 25px 1324px #fff,
    650px 558px #fff, 558px 1555px #fff, 782px 1910px #fff, 95px 1646px #fff,
    1794px 169px #fff, 1104px 1373px #fff, 965px 1386px #fff, 1818px 948px #fff,
    1848px 760px #fff, 1789px 937px #fff, 761px 1565px #fff, 945px 1788px #fff,
    1553px 93px #fff, 1344px 281px #fff, 832px 138px #fff, 819px 412px #fff,
    386px 1164px #fff, 36px 1157px #fff, 1150px 651px #fff, 63px 1824px #fff,
    338px 202px #fff, 1203px 764px #fff, 1825px 1211px #fff, 204px 1892px #fff,
    228px 313px #fff, 1511px 783px #fff, 497px 522px #fff, 744px 96px #fff,
    511px 1254px #fff, 179px 1572px #fff, 988px 411px #fff, 1539px 1119px #fff,
    687px 1122px #fff, 1615px 475px #fff, 512px 399px #fff, 816px 1040px #fff,
    341px 103px #fff, 450px 505px #fff, 1161px 742px #fff, 1926px 1476px #fff,
    764px 1893px #fff, 995px 1047px #fff, 572px 593px #fff, 143px 499px #fff,
    963px 861px #fff, 575px 178px #fff, 1492px 424px #fff, 1890px 1467px #fff,
    612px 1453px #fff, 573px 346px #fff, 1151px 945px #fff, 107px 45px #fff,
    345px 593px #fff, 1659px 456px #fff, 1984px 1612px #fff, 1103px 350px #fff,
    696px 908px #fff, 1227px 1093px #fff, 1525px 1198px #fff, 294px 648px #fff,
    955px 378px #fff, 1553px 114px #fff, 1355px 1009px #fff, 911px 1568px #fff,
    1807px 27px #fff, 933px 1952px #fff, 493px 1501px #fff, 1573px 1849px #fff,
    962px 471px #fff, 1078px 1745px #fff, 711px 381px #fff, 1617px 574px #fff,
    666px 1277px #fff, 1589px 1435px #fff, 41px 21px #fff, 1427px 252px #fff,
    748px 351px #fff, 1826px 261px #fff, 730px 1705px #fff, 30px 459px #fff,
    953px 1573px #fff, 725px 1653px #fff, 1628px 1144px #fff, 1070px 1879px #fff,
    362px 603px #fff, 605px 714px #fff, 749px 1979px #fff, 1569px 1753px #fff,
    335px 491px #fff, 1618px 1237px #fff, 362px 1735px #fff, 105px 489px #fff,
    806px 836px #fff, 1523px 781px #fff, 411px 1405px #fff, 858px 220px #fff,
    273px 89px #fff;
  animation: animStar 150s linear infinite;
}

.stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1106px 1979px #fff, 1572px 505px #fff, 25px 1324px #fff,
    650px 558px #fff, 558px 1555px #fff, 782px 1910px #fff, 95px 1646px #fff,
    1794px 169px #fff, 1104px 1373px #fff, 965px 1386px #fff, 1818px 948px #fff,
    1848px 760px #fff, 1789px 937px #fff, 761px 1565px #fff, 945px 1788px #fff,
    1553px 93px #fff, 1344px 281px #fff, 832px 138px #fff, 819px 412px #fff,
    386px 1164px #fff, 36px 1157px #fff, 1150px 651px #fff, 63px 1824px #fff,
    338px 202px #fff, 1203px 764px #fff, 1825px 1211px #fff, 204px 1892px #fff,
    228px 313px #fff, 1511px 783px #fff, 497px 522px #fff, 744px 96px #fff,
    511px 1254px #fff, 179px 1572px #fff, 988px 411px #fff, 1539px 1119px #fff,
    687px 1122px #fff, 1615px 475px #fff, 512px 399px #fff, 816px 1040px #fff,
    341px 103px #fff, 450px 505px #fff, 1161px 742px #fff, 1926px 1476px #fff,
    764px 1893px #fff, 995px 1047px #fff, 572px 593px #fff, 143px 499px #fff,
    963px 861px #fff, 575px 178px #fff, 1492px 424px #fff, 1890px 1467px #fff,
    612px 1453px #fff, 573px 346px #fff, 1151px 945px #fff, 107px 45px #fff,
    345px 593px #fff, 1659px 456px #fff, 1984px 1612px #fff, 1103px 350px #fff,
    696px 908px #fff, 1227px 1093px #fff, 1525px 1198px #fff, 294px 648px #fff,
    955px 378px #fff, 1553px 114px #fff, 1355px 1009px #fff, 911px 1568px #fff,
    1807px 27px #fff, 933px 1952px #fff, 493px 1501px #fff, 1573px 1849px #fff,
    962px 471px #fff, 1078px 1745px #fff, 711px 381px #fff, 1617px 574px #fff,
    666px 1277px #fff, 1589px 1435px #fff, 41px 21px #fff, 1427px 252px #fff,
    748px 351px #fff, 1826px 261px #fff, 730px 1705px #fff, 30px 459px #fff,
    953px 1573px #fff, 725px 1653px #fff, 1628px 1144px #fff, 1070px 1879px #fff,
    362px 603px #fff, 605px 714px #fff, 749px 1979px #fff, 1569px 1753px #fff,
    335px 491px #fff, 1618px 1237px #fff, 362px 1735px #fff, 105px 489px #fff,
    806px 836px #fff, 1523px 781px #fff, 411px 1405px #fff, 858px 220px #fff,
    273px 89px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
