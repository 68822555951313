.overlay-loader {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  .masked-wrapper {
    display: block;
    position: absolute;
    width: 260px;
    height: 88px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    transition: top 2s ease-in-out, left 2s ease-in-out;
    .masked-loader {
      display: block;
      width: 260px;
      height: 88px;
      position: absolute;
      top: 0;
      left: 0;
      mask: url(./Assets/SVG/Logo.svg) 0% 50%/260px 88px;
      mask-repeat: no-repeat;
      background-image: url(./Assets/SVG/Logo.svg);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 260px;
      transition: width 3s ease-in-out;
    }
  }
  .masked-outline {
    display: block;
    width: 260px;
    height: 88px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-image: url(./Assets/SVG/wpmeOutline.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 260px;
  }
}
